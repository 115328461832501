export default {
  MAX_ATTACHMENT_SIZE: 5000000,
    s3: {
      REGION: "us-east-2",
      BUCKET: "notes-app-uploads-ah91"
    },
    apiGateway: {
      REGION: "us-east-2",
      URL: "https://fo4a7aoful.execute-api.us-east-2.amazonaws.com/prod"
    },
    cognito: {
      REGION: "us-east-2",
      USER_POOL_ID: "us-east-2_4nHN1zgnP",
      APP_CLIENT_ID: "rmqvfqcse8hj5m5uu9ltjrgnk",
      IDENTITY_POOL_ID: "us-east-2:dfed5e6e-fce7-47cd-8d96-f1f6cfeff275"
    }
  };  